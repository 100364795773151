<template>
  <div class="">
    <transition name="showUp">
      <div
        class="fixed flex inset-0 bg-black bg-opacity-25 z-[9999]"
        v-if="value"
        @click.self="handleClose"
      ></div>
    </transition>
    <transition name="comeIn">
      <div
        class="fixed flex inset-0 z-[9999]"
        v-if="value"
        @click.self="handleClose"
      >
        <div
          v-if="value"
          id="dialog-modal"
          class="max-h-screen bg-white rounded-xl m-auto"
          style="max-width: 35rem;"
        >
          <div class="flex pl-4 pr-1 text-gray-700 items-center justify-center m-4 mt-7">
            <h4 class="font-bold text-xl text-gray-700">{{ title }}</h4>
            <a
              href="#"
              class="text-neutral-700 hover:text-project-500 ml-auto -mt-4"
              @click.prevent="handleClose"
            ><svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path></svg
            ></a>
          </div>
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["title", "value"],
  data() {
    return {};
  },
  methods: {
    handleClose() {
      //this.value = false;
      this.$emit("close-dialog", false);
    }
  }
};
</script>

<style scoped>
.showUp-enter-active,
.showUp-leave-active {
  transition: opacity 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.showUp-enter,
.showUp-leave-to {
  opacity: 0;
}

.showUp-leave,
.showUp-enter-to {
  opacity: 1;
}

.comeIn-enter-active,
.comeIn-leave-active {
  transition: opacity 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.comeIn-enter,
.comeIn-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.comeIn-leave,
.comeIn-enter-to {
  opacity: 1;
  transform: scale(1);
}
@media screen and (max-width: 640px) {
  #dialog-modal{
    width: 90%;
  }
}
</style>
