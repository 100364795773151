<template>
  <div class="">
    <!-- Dialog -->
    <ChangelogSuggestDialog title="Let's create your changelog entry" v-model="this.showSuggestionModal" @close-dialog="closeDialog();">
      <form>
        <div class="flex flex-col justify-center ml-8 mr-8">
          <transition name="fade" v-if="!isLoading && isError">
            <div class="alert alert--bad rounded-lg -mt-1 mb-4">
              <p class="text-md">{{'There was an error generating the text.'}} <a href="#" class="text-white underline" @click.prevent="createDraft()">{{'Please try again...'}}</a></p>
            </div>
          </transition>
          <p class="text-gray-500 -mt-1 mb-8">Choose an idea from your board and let FeedBear generate a changelog for you. You can review and edit the content before publishing.</p>

          <p class="text-gray-600 font-bold mb-2">Select an idea</p>
          <div class="relative">
            <div 
              @click="isDropdownOpen = !isDropdownOpen"
              class="input cursor-pointer flex justify-between items-center"
            >
              <span>{{ selectedPostTitle || 'Select an idea' }}</span>
              <svg 
                class="w-4 h-4 transform transition-transform" 
                :class="{ 'rotate-180': isDropdownOpen }"
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            <div 
              v-if="isDropdownOpen" 
              class="absolute z-50 bg-white rounded-lg shadow-lg border p-2 w-full mt-1"
            >
              <div class="max-h-60 overflow-y-auto">
                <div 
                  v-for="option in options" 
                  :key="option.id"
                  @click="selectPost(option)"
                  class="cursor-pointer p-2 rounded hover:bg-neutral-50"
                  :class="{ 'bg-neutral-50': selectedPost === option.id }"
                >
                  <div class="text-sm">{{ option.text }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex mt-4 justify-between">
            <a href="/edit?view=ai_settings" class="text-sm text-gray-600 mt-2">
              Edit AI Settings
            </a>
            <div class="flex flex-row justify-between mb-6">
              <button type="submit" class="btn-main text-sm rounded pl-10 pr-10" @click.prevent="createDraft">
                <span v-if="this.isLoading == null || this.isError">Create draft</span> <Spinner class="loader-wrapper" border-color="#ffffff" v-if="this.isLoading != null && !this.isError"/>
              </button>
            </div>
          </div>
        </div>
      </form>
    </ChangelogSuggestDialog>
  </div>
</template>

<script>
import ChangelogSuggestDialog from "./changelog_suggest_dialog";
import Spinner from "../projects/spinner";

export default {
  props: ["show-suggestion-modal"],
  data() {
    return {
      selectedPost: null,
      selectedPostTitle: null,
      isDropdownOpen: false,
      posts: [],
      isLoading: null,
      isError: false,
      options: [],
    };
  },
  components: {
    ChangelogSuggestDialog,
    Spinner,
  },
  computed:{
  },
  mounted() {
    Rails.ajax({
      url: "/posts/all",
      type: "GET",
      dataType: "json",
      beforeSend: function () {
        return true;
      },
      success: (data) => {
        data.forEach((post) => {
          this.options.push({
            id: post.id,
            text: post.title,
          });
        });
        this.selectedPost = data[0].id;
        this.selectedPostTitle = data[0].title;
      },
      error: (data, status) => {
        console.log(data);
      },
    });
    document.addEventListener('click', this.handleClickOutside);
  },
  created() {
  },
  methods: {
    createDraft() {
      this.isLoading = true;
      this.isError = false;
      let data = new FormData();
      data.append("post_id", this.selectedPost);
      Rails.ajax({
        url: '/changelog/suggest_entry',
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          window.location.href = `/updates/${data.id}/edit`;
        },
        error: (data, status) => {
          console.log(data);
          this.isLoading = false;
          this.isError = true;
        },
      });
    },
    closeDialog(){
      //this.showSuggestionModal = false;
      this.$emit("close-modal", false);
    },
    selectPost(option) {
      this.selectedPost = option.id;
      this.selectedPostTitle = option.text;
      this.isDropdownOpen = false;
    },
    handleClickOutside(event) {
      if (this.isDropdownOpen) {
        const dropdownElement = event.target.closest('.relative');
        if (!dropdownElement) {
          this.isDropdownOpen = false;
        }
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
}
</script>
<!--<style scoped>
.iconColor:hover svg{
  fill: white;
}
</style>-->

<style lang="scss">
.input {
  @apply w-full px-3 py-2 border border-gray-300 rounded-lg bg-white;
}

.changelog-ai-select-2 {
  .dropdown-wrapper {
    display: none !important;
  }

  .select2-container--classic .select2-selection--single {
    height: 45px;
    border: 1px solid #dddddd;
    background: linear-gradient(180deg, #ffffff 0%, #efefef 100%);
  }

  .select2-container--classic .select2-selection--single .select2-selection__rendered {
    height: 45px;
    padding: 14px 13px;
    font-size: 14px;
    line-height: 14px;
  }

  .select2-container--classic .select2-selection--single .select2-selection__arrow {
    height: 44px;
    background: none;
    border-left: 0px;
  }

  @media screen and (max-width: 498px) {
    .select2-container {
      width: 280px !important;
    }
  }
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #ededed;
  color: black;
}
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #ddd;
}
</style>
