<template>
  <div>
    <section>
      <TeamAlert v-if="getCurrentProjectPlan && getPlanFeatures && !teamCheck" title="You can't add new team members" :description="getAppropriateDescription" :link="'/edit?view=billing'" :click="'Click here to upgrade'" class="mb-4"/>      
    </section>
    <div class="relative">
      <div class="absolute top-0 right-0">
        <a
          href="https://help.feedbear.com/article/26-team"
          target="_blank"
          data-beacon-article-sidebar="5f9ab077cff47e0017d2c848"
          @click.prevent="openHelp('5f9ab077cff47e0017d2c848', 'sidebar')"
          title="Help center"
          v-tippy="{ placement: 'top' }"
        >
          <simple-svg
            :filepath="helpIcon"
            :width="'2em'"
            :height="'2em'"
            style="display: inline"
          />
        </a>
      </div>
      <h3 class="text-xl font-bold text-neutral-800">Team members</h3>
      <div class="mt-4">
        <div class="mb-4">
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Search by name or email"
            class="input w-full"
          />
        </div>
        <div 
        v-if="filteredTeam.length > 0"
        class="rounded-lg overflow-hidden border text-sm">
          <table class="w-full" v-if="filteredTeam.length > 0">
            <thead class="bg-neutral-100 border-b">
              <tr>
                <th class="p-2 text-left pl-4 w-1/2">User</th>
                <th class="p-2 text-left w-1/4">Date added</th>
                <th class="p-2 text-left w-1/4">Role</th>
                <th class="p-2 pr-4 text-right w-1/6">Action</th>
              </tr>
            </thead>
            <tbody class="max-h-80 overflow-y-auto block">
              <tr v-for="member in filteredTeam" :key="member.id" class="hover:bg-neutral-50">
                <td class="p-2 pl-4 w-1/2">
                  <div class="flex items-center">
                    <avatar
                      class="avatar mr-3 border-2 border-white shadow-md flex-shrink-0"
                      :size="32"
                      :src="member.user.avatar"
                      :username="member.user.name"
                    ></avatar>
                    <div class="flex flex-col justify-center min-w-0">
                      <span class="font-medium">{{ member.user.name }}</span>
                      <div class="w-full leading-none">
                        <span 
                          class="text-neutral-800 text-sm truncate inline-block w-full"
                          v-tippy="{ 
                            content: member.user.email, 
                            placement: 'bottom',
                            maxWidth: 'none',
                            allowHTML: false,
                            interactive: true,
                            appendTo: 'parent'
                          }"
                        >{{ member.user.email }}</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="p-2 w-1/4 text-sm text-neutral-800">
                  {{ member.user.created_at | humanDate }}
                </td>
                <td class="p-2 w-1/4">
                  <div v-if="member.role !== 'owner'" class="relative">
                    <div 
                      @click.stop="editingMemberId = member.id"
                      class="cursor-pointer hover:text-neutral-600 text-sm" 
                      :data-role-trigger="member.id"
                    >
                      {{ getRoleLabel(member.role).split(' - ')[0] }}
                    </div>
                    <div 
                      v-if="editingMemberId === member.id" 
                      class="fixed z-50 bg-white rounded-lg shadow-lg border p-2 w-64"
                      :style="getDropdownPosition(member.id)"
                      ref="roleDropdown"
                    >
                      <div>
                        <div 
                          v-for="option in roleOptions" 
                          :key="option.value"
                          @click="member.role = option.value; updateMemberRole(member); editingMemberId = null"
                          class="cursor-pointer p-2 rounded hover:bg-neutral-50"
                          :class="{ 'bg-neutral-50': member.role === option.value }"
                        >
                          <div class="font-medium text-neutral-800">{{ option.label.split(' - ')[0] }}</div>
                          <div class="text-xs text-neutral-600">{{ option.label.split(' - ')[1] }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span 
                    v-else 
                    class="text-neutral-700 text-sm"
                    v-tippy="{ content: 'Project owners cannot be modified. Please contact support if you need to change the project owner.', placement: 'left' }"
                  >Owner</span>
                </td>
                <td class="p-2 pr-4 text-right w-1/6">
                  <a
                    href="#"
                    v-if="member.role !== 'owner'"
                    @click.prevent="confirmRemoveMember(member)"
                    >Remove</a
                  >
                  <span 
                    v-else 
                    class="text-neutral-700 text-sm"
                    v-tippy="{ content: 'Project owners cannot be removed. Please contact support if you need to change the project owner.', placement: 'left' }"
                  >Can't remove</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <transition name="fromLeft" mode="out-in" appear>
          <p
            class="mb-2 text-neutral-700 border border-dashed text-sm border-neutral-300 rounded-lg p-2"
            v-if="filteredTeam.length === 0 && !isMemberAdding"
          >
            No team members found
          </p>

          <p
            class="mb-2 ml-2 text-neutral-700"
            v-if="isMemberRemoving || isMemberAdding"
          >
            Working …
          </p>
        </transition>
        <div v-show="getCurrentProjectPlan && getPlanFeatures && teamCheck" class="mt-4 rounded-xl bg-neutral-100 p-3">
          <h4 class="font-semibold text-neutral-800 flex items-center">
            <svg
              class="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
              ></path>
            </svg>
            New team member
          </h4>

          <form action class="mt-3" @submit.prevent="addMember()">
            <div class="flex md:items-end flex-col md:flex-row items-stretch">
              <div class="md:mr-2 flex-1 mt-2 md:mt-0">
                <label for="name" class="label">Full name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="input"
                  placeholder="Example: Lily Potter"
                  v-model="newName"
                  required
                  :disabled="isMemberAdding"
                />
              </div>

              <div class="flex-1 md:mr-2 mt-2 md:mt-0">
                <label for="email" class="label">Email address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  class="input"
                  placeholder="Example: lily@company.com"
                  v-model="newEmail"
                  required
                  :disabled="isMemberAdding"
                />
              </div>

              <div class="flex-1 md:mr-2 mt-2 md:mt-0">
                <label for="role" class="label">Role</label>
                <div class="relative">
                  <div 
                    @click="editingMemberId = 'new'"
                    class="input cursor-pointer"
                    data-role-trigger
                  >
                    {{ getRoleLabel(newRole).split(' - ')[0] }}
                  </div>
                  <div v-if="editingMemberId === 'new'" class="absolute z-50 bg-white rounded-lg shadow-lg border p-2 w-64">
                    <div>
                      <div 
                        v-for="option in roleOptions" 
                        :key="option.value"
                        @click="newRole = option.value; editingMemberId = null"
                        class="cursor-pointer p-2 rounded hover:bg-neutral-50"
                        :class="{ 'bg-neutral-50': newRole === option.value }"
                      >
                        <div class="font-medium text-neutral-800 text-sm">{{ option.label.split(' - ')[0] }}</div>
                        <div class="text-xs text-neutral-600">{{ option.label.split(' - ')[1] }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                class="btn-main mt-2 md:mt-0 justify-center"
                :disabled="isMemberAdding"
              >
                Invite
              </button>
            </div>
            <small v-if="emailError.length > 1" class="text-red-500">{{
              emailError
            }}</small>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import TeamAlert from "./plan_alert"
import {mapGetters} from "vuex"
import helpIcon from "images/icons/help-circle.svg";

export default {
  props: ["admins", "project"],

  data: function () {
    return {
      newEmail: "",
      newName: "",
      newRole: "editor",
      roleOptions: [
        { value: "admin", label: "Admin - Full access to project settings" },
        { value: "editor", label: "Editor - Can edit and create boards" },
        { value: "moderator", label: "Moderator - Can moderate content" }
      ],
      emailError: "",
      isMemberAdding: false,
      isMemberRemoving: false,
      team: this.admins,
      helpIcon,
      searchQuery: "",
      isMemberUpdating: null,
      editingMemberId: null,
    };
  },

  components: { Avatar, TeamAlert },
  computed:{
    ...mapGetters(['getPlanFeatures','getCurrentProjectPlan']),
    teamCheck(){
       if(this.getCurrentProjectPlan.name!="trial" && this.getCurrentProjectPlan.active_status){
        const plans = this.getPlanFeatures;
        const currentPlan = this.getCurrentProjectPlan;
        return plans[currentPlan?.name]?.team ? this.team.length < plans[currentPlan.name].team : this.team.length < this.project.team_member_count ;
      }
      else{
        return this.getCurrentProjectPlan.active_status;
      }
    },
    getAppropriateDescription(){
      if(this.getCurrentProjectPlan.name!="trial"){
        if(this.getCurrentProjectPlan.active_status) return 'You have reached your limit of team members. To add more, please upgrade your plan.';
        else return 'Your subscription failed to update. To add more team members, please update your card.'
      }
      else{
        return 'Your trial period has expired. Please upgrade your plan.';
      }
    },
    filteredTeam() {
      if (!this.searchQuery) return this.team;
      const query = this.searchQuery.toLowerCase();
      return this.team.filter(member => 
        member.user.name.toLowerCase().includes(query) ||
        member.user.email.toLowerCase().includes(query)
      );
    }
  },
  methods: {
    isEmailValid: function () {
      // Not perfect, but good enough to prevent typos
      return /^[\w.+-]+@[\w-]+\.[\.\w+-]*\w$/.test(this.newEmail);
    },
    openHelp(article_id, type) {
      Beacon("article", article_id, {
        type: type,
      });
    },
    addMember: function () {
      this.emailError = "";

      if (!this.isEmailValid()) {
        this.emailError =
          'Not a valid email address. Please enter an email address like "support@feedbear.com"';
        return;
      }

      this.isMemberAdding = true;
      var params = new FormData();
      params.append("email", this.newEmail);
      params.append("name", this.newName);
      params.append("role", this.newRole);

      Rails.ajax({
        url: "/team_members",
        type: "POST",
        data: params,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.newEmail = "";
          this.newName = "";
          this.newRole = "editor";
          this.$store.dispatch('getAllWarnings')
          this.$store.dispatch('fetchCurrentPlan')
          this.team = data;
          this.isMemberAdding = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isMemberAdding = false;
        },
      });
    },

    confirmRemoveMember: function (member) {
      var result = confirm(
        `You are about to remove ${member.user.name} from the team. Proceed?`
      );
      if (result) {
        this.removeMember(member);
      }
    },

    removeMember: function (member) {
      this.isMemberRemoving = true;

      Rails.ajax({
        url: "/team_members/" + member.id,
        type: "DELETE",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.team = data;
          this.isMemberRemoving = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isMemberRemoving = false;
        },
      });
    },

    updateMemberRole: function(member) {
      const originalRole = member.role;
      this.isMemberUpdating = member.id;
      
      var params = new FormData();
      params.append("role", member.role);

      Rails.ajax({
        url: `${window.location.origin}/projects/update_project_user_role/${member.id}`,
        type: "PATCH",
        data: params,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.team = data;
          this.isMemberUpdating = null;
        },
        error: (data) => {
          console.error("Failed to update role:", data);
          this.isMemberUpdating = null;
          member.role = originalRole;
          if (this.$toast) {
            this.$toast.error("Failed to update team member role");
          }
        },
      });
    },

    getRoleLabel: function(role) {
      const option = this.roleOptions.find(opt => opt.value === role);
      return option ? option.label : role;
    },

    handleClickOutside(event) {
      if (this.editingMemberId !== null) {
        const dropdownElement = event.target.closest('.absolute.z-50.bg-white');
        const roleTrigger = event.target.closest('[data-role-trigger]');
        
        if (!dropdownElement && !roleTrigger) {
          this.editingMemberId = null;
        }
      }
    },

    getDropdownPosition(memberId) {
      const trigger = document.querySelector(`[data-role-trigger="${memberId}"]`);
      if (!trigger) return {};
      
      const rect = trigger.getBoundingClientRect();
      return {
        top: `${rect.bottom}px`,
        left: `${rect.left}px`
      };
    },
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  filters: {
    humanDate: function (date) {
      let event = new Date(date);
      return event.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    },
  },
};
</script>

<style scoped>
.new-member {
  background-color: #f7f4f2;
  border-radius: 8px;
  padding: 0.8rem;
}

tbody {
  display: block;
  max-height: 20rem;
  overflow-y: auto;
}

thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
</style>
