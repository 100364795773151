<template>
  <div class="relative" ref="wrapper">
    <div
      class="cursor-pointer select-none focus:outline-none focus:shadow-none focus:text-project-500 hover:text-project-500"
      @click="open()"
      @keydown.enter="open()"
      ref="button"
      role="button"
      tabindex="0"
    >
      <slot name="button"></slot>
    </div>
    <transition name="popdown">
      <div
        class="absolute bg-white z-[9999] mt-2 rounded-lg shadow-lg text-base max-h-[80vh] overflow-y-auto"
        :class="[alignmentClass, mTopClass, { 'top-0': mTopClass }]"
        v-if="isOpen"
        ref="items"
      >
        <slot name="items"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["align", "autoclose", "mTopClass"],

  data() {
    return {
      isOpen: false,
      alignment: this.align || "left",
    };
  },

  computed: {
    alignmentClass() {
      return this.alignment + "-0";
    },
  },

  methods: {
    open: function () {
      if (!this.isOpen) {
        this.isOpen = true;
        window.addEventListener("click", this.closeDropdown, true);
        this.$nextTick(() => {
          this.$refs.items.querySelector(".hidden").classList.remove("hidden");
        });
      }
    },

    closeDropdown: function (event) {
      if (this.autoclose) {
        this.isOpen = false;
        window.removeEventListener("click", this.closeDropdown, true);
      } else {
        if (
          this.$refs.wrapper &&
          this.$refs.wrapper.contains(event.target) === false
        ) {
          this.isOpen = false;
          window.removeEventListener("click", this.closeDropdown, true);
        }
      }
    },
  },
};
</script>

<style scoped>
.safe-height {
  max-height: 70vh;
}
</style>
